<template>
  <div class="authBox">
    <NavBar title="实名认证"></NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <TextBox color="title" :font-size="36" :bold="5">请完善您的个人信息</TextBox>
      <van-field
        label="国家地区"
        :label-width="parseInt($pxToPxRatio(130), 10)"
        :value="country"
        right-icon="arrow"
        :border="false"
        readonly
        @click="$refs.selectCountry.init()"
      ></van-field>
      <van-divider />
      <van-field
        v-model="name"
        label="姓名"
        :label-width="parseInt($pxToPxRatio(130), 10)"
        placeholder="请输入姓名"
        :border="false"
      ></van-field>
      <van-divider />
      <van-field
        v-model="card"
        label="证件号码"
        :label-width="parseInt($pxToPxRatio(130), 10)"
        placeholder="请输入本人证件证号"
        :border="false"
      ></van-field>
      <van-divider />
      <Space :height="42"></Space>
      <TextBox color="title" :font-size="36" :bold="5">{{ china ? '请上传身份证的正反面' : '请上传护照照片' }}</TextBox>
      <Space :height="30"></Space>
      <template v-if="china">
        <van-uploader :after-read="file => uploadFile('front', file)">
          <van-image
            :width="parseInt($pxToPxRatio(678), 10)"
            :height="parseInt($pxToPxRatio(309), 10)"
            lazy-load
            fit="contain"
            :src="require('@/assets/images/personal/admin/a2.png')"
          >
            <van-image
              :width="parseInt($pxToPxRatio(338), 10)"
              :height="parseInt($pxToPxRatio(200), 10)"
              lazy-load
              fit="contain"
              class="innerUploadImage"
              :src="fileList.front.after || fileList.front.before"
            >
              <div class="reloadUpload" v-if="fileList.front.after"></div>
            </van-image>
          </van-image>
        </van-uploader>
        <Space :height="20"></Space>
        <van-uploader :after-read="file => uploadFile('end', file)">
          <van-image
            :width="parseInt($pxToPxRatio(678), 10)"
            :height="parseInt($pxToPxRatio(309), 10)"
            lazy-load
            fit="contain"
            :src="require('@/assets/images/personal/admin/a1.png')"
          >
            <van-image
              :width="parseInt($pxToPxRatio(338), 10)"
              :height="parseInt($pxToPxRatio(200), 10)"
              lazy-load
              fit="contain"
              class="innerUploadImage"
              :src="fileList.end.after || fileList.end.before"
            >
              <div class="reloadUpload" v-if="fileList.end.after"></div>
            </van-image>
          </van-image>
        </van-uploader>
      </template>
      <template v-else>
        <van-uploader :after-read="file => uploadFile('passport', file)">
          <van-image
            :height="parseInt($pxToPxRatio(309), 10)"
            lazy-load
            fit="contain"
            :src="fileList.passport.after || fileList.passport.before"
          />
        </van-uploader>
      </template>
      <Space :height="33"></Space>
      <TextBox color="intro1" :font-size="24" :bold="5">注意事项：</TextBox>
      <Space :height="10"></Space>
      <TextBox color="intro1" :font-size="24">·大陆公民持有本人有效二代身份证。 </TextBox>
      <Space :height="10"></Space>
      <TextBox color="intro1" :font-size="24">·拍摄时确保身份证边框完整，字迹清晰，亮度均匀。</TextBox>
      <Space :height="10"></Space>
      <TextBox color="intro1" :font-size="24">·仅支持PNG，JPG，JPE格式，每张大小限制在{{ maxFileSize }}M以内。</TextBox>
      <Space :height="40"></Space>
      <van-button type="primary" block @click="submit" :loading="$store.state.submitLoading">提交审核</van-button>
      <Space :height="73"></Space>
    </div>
    <SelectCountry ref="selectCountry" :callback="countryCallback"></SelectCountry>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      name: '',
      card: '',
      country: '中国',
      fileList: {
        front: {
          before: require('@/assets/images/personal/admin/a6.png'),
          after: '',
        },
        end: {
          before: require('@/assets/images/personal/admin/a5.png'),
          after: '',
        },
        passport: {
          before: require('@/assets/images/personal/admin/a3.png'),
          after: '',
        },
      },
      failDetail: '',
      // 单位 兆
      maxFileSize: 10,
    };
  },
  created() {
    this.getFailDetail();
  },
  // mounted() {},
  methods: {
    reset() {
      this.name = '';
      this.card = '';
      this.fileList = {
        front: {
          before: require('@/assets/images/personal/admin/a2.png'),
          after: '',
        },
        end: {
          before: require('@/assets/images/personal/admin/a1.png'),
          after: '',
        },
        passport: {
          before: require('@/assets/images/personal/admin/a3.png'),
          after: '',
        },
      };
    },
    getFailDetail() {
      this.$http('post', '/v1/user/authenticationDetails').then(res => {
        if (!res.data) return;
        this.$dialog.alert({
          message: `认证失败：${res.data.failReason}`,
        });
        this.failDetail = res.data;
        this.name = res.data.cardName;
        this.card = res.data.cardId;
        this.country = res.data.regions;
        this.fileList = {
          front: {
            before: require('@/assets/images/personal/admin/a2.png'),
            after: res.data.frontUrl,
          },
          end: {
            before: require('@/assets/images/personal/admin/a1.png'),
            after: res.data.backUrl,
          },
          passport: {
            before: require('@/assets/images/personal/admin/a3.png'),
            after: res.data.passportUrl,
          },
        };
      });
    },
    submit: _.debounce(function() {
      if (!this.name) {
        this.$toast('姓名不能为空');
        return;
      }
      if (!this.card) {
        this.$toast('证件号码不能为空');
        return;
      }
      if (this.china) {
        if (!this.fileList.front.after) {
          this.$toast('身份证正面不能为空');
          return;
        }
        if (!this.fileList.end.after) {
          this.$toast('身份证反面不能为空');
          return;
        }
      } else {
        if (!this.fileList.passport.after) {
          this.$toast('护照不能为空');
          return;
        }
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/user/authentication', {
        backUrl: this.fileList.end.after,
        cardId: this.card,
        cardName: this.name,
        frontUrl: this.fileList.front.after,
        id: this.failDetail?.id,
        passportUrl: this.fileList.passport.after,
        regions: this.country,
        type: Number(!this.china),
      })
        .then(res => {
          let that = this;
          this.$toast('提交成功');
          that.$router.back();
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
    countryCallback(res) {
      this.country = res.zh;
      this.reset();
    },
    uploadFile(target, file) {
      if (file.file.size > this.maxFileSize * 1024 * 1024) {
        this.$toast(`图片大小不能超过${this.maxFileSize}M`);
        return;
      }
      this.$http('file', '/v1/cdn/uploadImg', {
        file: file.file,
      }).then(res => {
        this.fileList[target].after = res.data;
      });
    },
  },
  // watch: {},
  computed: {
    china() {
      return this.country === '中国';
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
/deep/ .reloadUpload {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  transform: translate(30%, -30%);
  z-index: 0;
  background: url('~@/assets/images/personal/admin/a7.png') no-repeat center;
  background-size: cover;
}
.innerUploadImage {
  position: absolute;
  right: 36px;
  top: 55px;
}
.authBox {
  .content {
    padding: 71px 50px 31px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
    font-size: 30px;
    color: #0e0d51;
  }
  .buttonBox {
    width: 274px;
    margin: 0 auto;
    margin-bottom: 37px;
  }
  /deep/ .van-field {
    input {
      color: #2c395b;
    }
    .van-field__label {
      color: #2c395b;
    }
  }
}
</style>
